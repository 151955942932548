// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";

// Then add additional custom code here
@mixin color-scheme($name) {
  @media (prefers-color-scheme: #{$name}) {
    @content;
  }
}

.wdbtn {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.wdbtn:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}

body {
  background: #eee;
  color: #222;
  a, a:hover, a:visited {
    color: #1e2125;
  }
  @include color-scheme(dark) {
    // Insert dark mode styles here
    background: #222;
    color: #ddd;
    a, a:hover, a:visited {
      color: #adb5bd;
    }
    .wdbtn {
      color: #000;
      background-color: #f8f9fa;
      border-color: #f8f9fa;
    }
    .wdbtn:hover {
      color: #000;
      background-color: #f9fafb;
      border-color: #f9fafb;
    }
  }
}

.wallie-logo-hero {
  width: 256px;
  height: 256px;
  background-size: contain;
  background-image: url("./wallie-logo-2021.png");
  @include color-scheme(dark) {
    // Insert dark mode styles here
    background-image: url("./wallie-logo-2021-dark.png");
  }
}

@media screen and (max-width: 768px) {
  .wallie-logo-hero {
    width: 128px;
    height: 128px;
  }
}

.wallie-logo-page {
  width: 64px;
  height: 64px;
  background-size: contain;
  background-image: url("./wallie-logo-2021.png");
  margin-top: 32px;
  @include color-scheme(dark) {
    // Insert dark mode styles here
    background-image: url("./wallie-logo-2021-dark.png");
  }
}
.wallie-404 {
  background-size: cover;
  background-image: url("./annie-theby-EXCeGbyolPY-unsplash.png");
}
.wallie-404-sh-title {
  text-shadow: 0 0 20px #eee ;
  @include color-scheme(dark) {
    // Insert dark mode styles here
    text-shadow: 0 0 20px #1e2125 ;
  }
}
.wallie-404-sh-text {
  text-shadow: 0 0 5px #eee ;
  @include color-scheme(dark) {
    // Insert dark mode styles here
    text-shadow: 0 0 20px #1e2125 ;
  }
}
